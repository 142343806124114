@mixin base-transition {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin transition($type) {
  transition: $type;
  -moz-transition: $type;
  -webkit-transition: $type;
  -moz-transition: $type;
}

@mixin animateMovementY($y, $speed: 0.5s) {
  -webkit-transform: translateY($y);
  -moz-transform: translateY($y);
  transform: translateY($y);
  @include transition(-webkit-transform $speed ease);
  @include transition(-moz-transform $speed ease);
  @include transition(transform $speed ease);
}


