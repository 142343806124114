@use '../common/breakpoints.scss' as jmmBreakpoints;

/*
** Font Style Guide
** You have the opportunity to use font sizes variables, 
** or mixins, who created on their basis.
*/

// Font names
:export {
  /*
  ** Desktop Sizes Variables
  ** 1rem = 16px
  */
  fontSize-base: 1rem;
  fontSize-base-bigger: 1.125rem; //18px
  fontSize-base-medium: 0.875rem; // 14px
  fontSize-base-smMedium: 0.813rem; // 13px
  fontSize-base-small: 0.75rem; // 12px
  fontSize-base-extra-small: 0.625rem; // 10px

  // Headline text
  HeadlineDesktop-Size-1: 2.875rem; //46px
  HeadlineDesktop-Lineheight-1: 3.5rem; //56px

  HeadlineDesktop-Size-1-smaller: 2.375rem; //38px
  HeadlineDesktop-Lineheight-1-smaller: 2.875rem; //46px

  HeadlineDesktop-Size-2: 1.75rem; //28px
  HeadlineDesktop-Lineheight-2: 2.188rem; //35px

  HeadlineDesktop-Size-3: 1.25rem; //20px
  HeadlineDesktop-Lineheight-3: 1.625rem; //26px

  // Standard text
  TextNormalDesktop: 1rem; //16px
  TextNormalDesktop-Lineheight: 1.313rem; //21px

  // Small text
  TextSmallDesktop: 0.75rem; //12px
  TextSmallDesktop-Lineheight: 1rem; //16px

  // Button text
  ButtonTextDesktop-1: 1.25rem; //20px
  ButtonTextDesktop-Lineheight-1: 1.625rem; //26px

  ButtonTextDesktop-2: 1rem; //16px
  ButtonTextDesktop-Lineheight-2: 1.313rem; //21px

  // funnel introtext box
  FunnelIntroTextHeadline: 1.425rem; // 26px
  FunnelIntroTextHeadlineLineHeight: 1.625rem;
  funnelIntroTextHint: 1.2rem; // 26px

  /*
  ** Mobile View Sizes Variables 
  */

  // Headline text
  HeadlineMobile-Size-1: 1.625rem; //26px
  HeadlineMobile-Lineheight-1: 2rem; //32px

  HeadlineMobile-Size-2: 1.25rem; //20px
  HeadlineMobile-Lineheight-2: 1.563rem; //25px

  HeadlineMobile-Size-3: 1.25rem; //20px
  HeadlineMobile-Lineheight-3: 1.625rem; //26px

  // Standard text
  TextNormalMobile: 1rem; //16px
  TextNormalMobile-Lineheight: 1.313rem; //21px

  // Small text
  textSmallMobile: 0.75rem; //12px
  textSmallMobile-Lineheight: 1rem; //16px

  // Button text
  ButtonTextMobile-1: 1rem; //16px
  ButtonTextMobile-Lineheight-1: 1.313rem; //21px

  ButtonTextMobile-2: 1rem; //16px
  ButtonTextMobile-Lineheight-2: 1.313rem; //21px

  rangeSliderValue: 1rem; // 16px
  borderRadius: 14px;

  // Checkbox label
  checkBoxLabelValue: 1.2rem; // 1.313

  //Funnel text
  funnelFontSize: 1.2rem;

  //For Funnel, Matching and static JO
  baseFont: 'Arial', 'Roboto', sans-serif;
  baseFontBold: 'Arial Bold', 'Roboto Bold',  sans-serif;
  baseFontExtraBold: 'Arial Bold', 'Roboto Bold', sans-serif;
}

//Exports for Mixins
:root {
  --HeadlineDesktop-Size-1: 2.875rem; //46px
  --HeadlineDesktop-Lineheight-1: 3.5rem; //56px

  --HeadlineMobile-Size-1: 1.625rem; //26px
  --HeadlineMobile-Lineheight-1: 2rem; //32px

  --HeadlineMobile-Size-2: 1.25rem; //20px
  --HeadlineMobile-Lineheight-2: 1.563rem; //25px

  --HeadlineMobile-Size-3: 1.25rem; //20px
  --HeadlineMobile-Lineheight-3: 1.625rem; //26px

  --HeadlineDesktop-Size-2: 1.75rem; //28px
  --HeadlineDesktop-Lineheight-2: 2.188rem; //35px

  --HeadlineDesktop-Size-3: 1.25rem; //20px
  --HeadlineDesktop-Lineheight-3: 1.625rem; //26px

  --TextNormalDesktop: 1rem; //16px
  --TextNormalDesktop-Lineheight: 1.313rem; //21px

  --TextNormalMobile: 1rem; //16px
  --TextNormalMobile-Lineheight: 1.313rem; //21px

  --ButtonTextDesktop-1: 1.25rem; //20px
  --ButtonTextDesktop-Lineheight-1: 1.625rem; //26px

  --ButtonTextDesktop-2: 1rem; //16px
  --ButtonTextDesktop-Lineheight-2: 1.313rem; //21px

  --FunnelIntroTextHeadline: 1.425rem; // 26px
  --FunnelIntroTextHeadlineLineHeight: 1.625rem;

  --HeadlineDesktop-Size-1-smaller: 2.375rem; //38px
  --HeadlineDesktop-Lineheight-1-smaller: 2.875rem; //46px

  --TextSmallDesktop: 0.75rem; 
  --TextSmallDesktop-Lineheight: 1rem; 

  --ButtonTextMobile-2: 1rem; //16px
  --ButtonTextMobile-Lineheight-2: 1.313rem; //21px

  --fontSize-base: 1rem;
  --fontSize-base-bigger: 1.125rem; //18px
  --fontSize-base-medium: 0.875rem; // 14px
  --fontSize-base-smMedium: 0.813rem; // 13px
  --fontSize-base-small: 0.75rem; // 12px
  --fontSize-base-extra-small: 0.625rem; // 10px
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: 0 !important;

  &:focus {
    outline: 0 !important;
  }
}

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: 'Roboto', 'Arial', serif;
  box-sizing: border-box;
  overflow-y: scroll;
  line-height: 150%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  //May be visible for a short time while the site is loading. 
  //Then, when the rest of the css is loaded, it is replaced with the desired one. 
  //Looks better than black.
  color:gray;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
